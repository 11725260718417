import axios from "axios";
import config from '../config';

function backendRequest(propsUrl, data, method) {
  let url = config.REACT_APP_API_URL + propsUrl;
  if (!url) throw new Error("requests can not be made without a url");
  if (propsUrl.includes("http")) url = propsUrl;
  const requestConfig = {
    // timeout: 5000,
    url,
    data,
    method,
  };
  return axios(requestConfig)
    .then(({ data }) => {
      if (!data) throw new Error(`no data retrieved from call - ${url}`);
      return data;
    })
    .catch((e) => {
      const { message } = e;
      console.error(`server error in - ${url}
      ${message}
      `);
      return { error: message };
    });
}

export async function getLanguages() {
  const languages = await backendRequest("/api/language");
  return languages;
}

export async function login(form = {}) {
  try {
    const {
      isMacau = false,
      password = "",
      "dist-id": repDID = "",
      guid = "",
      onBehalfRepGuid = "",
      loggedRepGuid = "",
      onBehalfCustomerGuid = "",
      redirectUrl = "",
      referer = "",
    } = form;
    const response = await backendRequest(
      `/api/login/shopify`,
      {
        repDID,
        password,
        guid,
        onBehalfRepGuid,
        loggedRepGuid,
        onBehalfCustomerGuid,
        redirectUrl,
        referer,
      },
      "POST"
    );
    const { error: _error, message, url } = response;
    const finalUrl = url === "none" ? "" : url;
    const error = finalUrl
      ? undefined
      : message || "Unable to login please try again";
    console.warn("login failure message - " + _error);
    return {
      error,
      url,
    };
  } catch (e) {
    console.warn(e);
    return { error: "Unable to login please try again" };
  }
}
export async function resetPassword(repDID, token, password) {
  const response = await backendRequest(
    `/api/chinaPasswordReset`,
    {
      repDID,
      token,
      password,
    },
    "post"
  );
  return response;
}
export async function forgotPassword({ email, locale }) {
  const response = await backendRequest(
    `/api/login/forgot-password`,
    { email, lang: locale },
    "post"
  );
  return response;
}
